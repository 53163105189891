import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          Наші послуги | UvixenGood Прокат автомобілів
        </title>
        <meta
          name={"description"}
          content={"Створено, щоб покращити ваш досвід водіння\n"}
        />
        <meta
          property={"og:title"}
          content={
            "Наші послуги | UvixenGood Прокат автомобілів"
          }
        />
        <meta
          property={"og:description"}
          content={"Створено, щоб покращити ваш досвід водіння"}
        />
        <meta
          property={"og:image"}
          content={"https://cool.uvixengood.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cool.uvixengood.com/img/favicon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        background="#EDF2F6"
        sm-padding="60px 0 60px 0"
        quarkly-title="Schedule-8"
      >
        <Override
          slot="SectionContent"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px 30px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="30px"
          md-grid-gap="32px"
        />
        <Box
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          align-items="center"
          display="flex"
          grid-column="1 / span 3"
          lg-grid-column="auto / auto"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline1"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            Створено, щоб покращити ваш досвід водіння
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--darkL2"
            md-text-align="center"
            text-align="center"
            max-width="800px"
            sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
          >
            Ми пропонуємо виняткові послуги, щоб покращити ваш досвід оренди розкішних автомобілів. У UvixenGood ми прагнемо надати більше, ніж просто автомобіль - ми надаємо комплексний пакет послуг, розроблений для того, щоб зробити кожен аспект вашого прокату бездоганним і чудовим.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Консультація перед поїздкою: Обговоріть свої плани подорожі з нашими експертами, які допоможуть скласти оптимальний маршрут і визначать місця, які обов'язково варто відвідати.
            <br />
            <br />
            Індивідуальні рекомендації: Отримуйте персоналізовані пропозиції щодо ресторанів, розваг та дозвілля, щоб покращити вашу подорож.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Зустріч і привітання: Після прибуття вас зустріне представник UvixenGood, який проведе вас до вашого автомобіля.
            <br />
            <br />
            Допомога з багажем: Дозвольте нам подбати про ваш багаж від аеропорту до автомобіля для безпроблемного початку вашої подорожі.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
           Професійні водії: Відчуйте комфорт, коли за кермом професійний водій, навчений надавати виняткові послуги.
            <br />
            <br />
Гнучкий графік: Замовляйте водія на кілька годин або на весь термін оренди, відповідно до ваших потреб.

          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Корпоративні рахунки: Скористайтеся індивідуальними програмами оренди, розробленими для задоволення потреб ділових мандрівників.
            <br />
            <br />
Транспорт для заходів: Забезпечте безперебійну транспортну логістику для конференцій, зустрічей або корпоративних виїзних заходів.

          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Покращені зручності в автомобілі: Насолоджуйтесь покращеними зручностями, такими як аудіосистеми преміум-класу, Wi-Fi та клімат-контроль у салоні.
            <br />
            <br />
Особливі побажання: Від дитячих сидінь до розкішних наборів для пікніка - замовляйте додаткові речі, які покращать ваш досвід оренди.


          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Підтримка 24/7: Доступ до цілодобової допомоги для вирішення будь-яких проблем на дорозі.
            <br />
            <br />
Комплексне покриття: Від механічних збоїв до випадкових блокувань - залишайтеся захищеними за будь-яких обставин.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://cool.uvixengood.com/img/3.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
           Дізнайтеся більше та забронюйте свій досвід
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            В UvixenGood ми прагнемо забезпечити безпрецедентний рівень обслуговування, який відображає розкіш та якість нашого автопарку. Щоб отримати більш детальну інформацію про будь-яку з наших послуг або обговорити конкретні вимоги до вашої наступної оренди, будь ласка, зв'яжіться з нами. Наша команда готова допомогти вам у створенні ідеально підібраного досвіду прокату розкішних автомобілів.
            <br />
            <br />
            Подорожуйте розкішно з UvixenGood. Зв'яжіться з нами сьогодні, щоб дізнатися, як ми можемо перетворити ваші плани на незабутню розкішну подорож.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
